import React from "react";
import "./service-card.scss";
import ReactReadMoreReadLess from "react-read-more-read-less";

const ServiceCard = (props) => {
  const { name, img, details, longtextGold  } = props;
  // const longtextGold =
  //   "We at Bright Gold with our knowledge experts we assist our clients to get the purest form of gold as we extract gold of 995 to 999 fineness. We have utmost love and respect for our mother nature, so we do the best refining while taking care of the environment as well with the pollution reduction through AQUA REGIS process. Recycling precious metals, such as gold ores, scrap gold, silver or even jewellery by using the world’s leading technology and the best machinery in the business. The measure of Karat refers to the amount of gold in an object as compared to another alloy. Gold is more likely to be found in samples with a higher karate. For instance, 24-karat gold is 100% gold. In India, most of the people prefer 22 karat Gold, whereas if we talk about Europeans or USA, they prefer 18 Karat and 14 karat, simultaneously";

  return (
    <div className="service-card text-center">
      <img className="w-100 border-gold" src={img} />
      <h3 className="name fw-bold my-3">{name}</h3>
      <p className="small details details-001">{details}</p>
      {/* <a href={link} className="btn btn-read-more border-gold">
        READ MORE
      </a> */}
      <div className="small details">
      <ReactReadMoreReadLess
        charLimit={80}
        readMoreText={"...Read more ▼"}
        readLessText={"Read less ▲"}
        readMoreClassName="read-more-less--more"
        readLessClassName="read-more-less--less"
      >
        {longtextGold}
      </ReactReadMoreReadLess>
      </div>
    </div>
  );
};
export default ServiceCard;
