import React, { useState, useEffect } from 'react';
import pro2 from '../images/pro2.png';
import pro1 from '../images/pro1.png';
import pro3 from '../images/pro3.png';
import picc1 from '../images/picc1.jpg';
import picc2 from '../images/picc2.jpg';
import RTBG from '../images/Refining-Technique-bg.jpg';
import FeedbackForm from '../components/FeedbackForm/FeedbackForm';
import { products, services } from './products.json';
import lax from 'lax.js';
import './products.scss';
import Page from '../components/layout/Page';
import Banner from '../components/Banner/Banner';
import ProductDetails from '../components/ProductDetails';
import ServiceCard from '../components/ServiceCard';

const Products = () => {

  useEffect(() => {
    const isDesktop = window.innerWidth > 768;

    if (isDesktop) {
      // window.lax = { presets: lax.presets };

      lax.init();

      lax.addDriver('scrollY', function () {
        return window.scrollY;
      }, {inertiaEnabled: false});

      lax.addElements('.slide-right', {
        scrollY: {
          translateX: [
            ['elInY', 'elCenterY', 'elOutY'],
            [-150, 0, 0],
            {
              inertia: 5,
              inertiaMode: "absolute"
            }
          ],
          opacity: [
            ['elInY', 'elCenterY', 'elOutY'],
            [0.5, 1, 1],
          ],
        },
      });

      console.log(lax);

      lax.addElements('.slide-left', {
        scrollY: {
          translateX: [
            ['elInY', 'elCenterY', 'elOutY'],
            [100, 0, 0],
            {
              inertia: 5,
              inertiaMode: "absolute"
            }
          ],
          opacity: [
            ['elInY', 'elCenterY', 'elOutY'],
            [0.5, 1, 1],
          ],
        },
      });
    }
  }, []);

  return (
    <Page name="Products" className="products">
      <Banner bgImg="/images/products-page-banner.png" title="OUR PRODUCTS"
      productText="Talking about Gold, there are some explicit factors that portray the
      purity and fineness of Gold. BIS Mark, purity in Karat and fineness
      along with assaying & hallmarking centre’s identification
      mark/number, are the apparatus that we ensure at Bright Gold’s
      production. Our Products Includes:"  />
      {/* <section className="ribbon-bg">
        <div className="container p-custom">
          <p className="text-justify text">
            Talking about Gold, there are some explicit factors that portray the
            purity and fineness of Gold. BIS Mark, purity in Karat and fineness
            along with assaying & hallmarking centre’s identification
            mark/number, are the apparatus that we ensure at Bright Gold’s
            production. Our Products Includes:
          </p>
        </div>
      </section> */}
      {products.map((product, i) => (
        <ProductDetails
          key={product.name}
          variant={i % 2 === 0 ? 'dark' : 'light'}
          layout={i % 2 === 0 ? 'image-left' : 'image-right'}
          {...product}
        />
      ))}
      <section className="container p-5">
        <h2 className="section-title">Services</h2>
        <div className="row">
          {services.map((service) => (
            <div key={service.name} className="col-md-6">
              <ServiceCard {...service} />
            </div>
          ))}
        </div>
      </section>
    </Page>
  );
};

export default Products;
