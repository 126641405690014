import React from "react";

import "./banner.scss";

const Banner = (props) => {
  const { bgImg, title, productText, bannerheight } = props;

  return (
    <div
      className="banner"
      style={{ backgroundImage: `url(${bgImg})`, height: `${bannerheight}px` }}
    >
      <div className="overlay w-100 ptb-250 div-content">
        <div className="">
          <h1 className="title gold-gradient-text text-center text-uppercase fw-bold">
            {title}
          </h1>
          <p className="paragraph-font">{productText}</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
