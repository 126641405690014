import React from "react";
import ImgBgContainer from "../ImgBgContainer";

import "./product-details.scss";

const ProductDetails = (props) => {
  const { variant, layout, name, img, details, index } = props;
  return (
    <ImgBgContainer
      bgImg={variant === "dark" && "/images/surface-dark.jpg"}
      overlay={variant}
      className="product-details"
    >
      <div
        className={` ${
          layout === "image-right" ? "ribbon-bg" : "ribbon-bg-left"
        }`}
      >
        <div className="container p-5 ">
          <div className="row align-items-center">
            <div
              className={`col-md-6 text-center ${
                layout === "image-right" ? "order-1" : ""
              } ${index % 2 === 0 ? "slide-right" : "slide-left"}`}
            >
              <img
                className={`product-image my-3 ${
                  name === "MINTED COINS" ? "minted-coins" : ""
                }`}
                src={img}
              />
            </div>
            <div
              className={`col-md-6 text-justify ${
                variant === "dark" ? "text-light" : ""
              } ${index % 2 === 0 ? "slide-left" : "slide-right"}`}
            >
              <h3 className={variant === "dark" ? "gold-gradient-text" : ""}>
                {name}
              </h3>
              <p className="text-justify line-001">{details}</p>
            </div>
          </div>
        </div>
      </div>
    </ImgBgContainer>
  );
};

export default ProductDetails;
